import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { startSynchronization } from '../../../api/configuration';
import ErrorIcon from '../../../assets/svg/icons/error';
import SuccessIcon from '../../../assets/svg/icons/success';
import { startSynchronizationUrl, validateSynchronizationUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import Button from '../../ui/button';
import Loader from '../../ui/loader';
import { ISystemTabComponent } from '../types';
import { EConfigurationStates, IConfigurationData, IValidateConfiguration } from './types';
import { ELoaderColor } from '../../ui/loader/types';
import { ButtonType } from '../../ui/button/types';
import { ESystemTabsIds } from '../../../pages/systems/item/types';
import { IApiError } from '../../../typings/api';
import { paths } from '../../../constants/paths';
import { getRequest } from '../../../api';
import DangerIcon from '../../../assets/svg/icons/danger';
import { EPageQueryParams } from '../../../typings/searchParams';
import { useAppSelector } from '../../../hooks/hooks';
import { checkIsAdmin } from '../../../store/selectors/profile';

const ConfigurationTab: FC<ISystemTabComponent> = ({
  systemId = '',
  activeTabKey,
  tabId,
  isAccessGroupsCheck = true,
  permissions = {},
  onChangeTab = () => {},
}) => {
  const { sendRequest: startConfiguration, data, loading } = useApi<IConfigurationData>(startSynchronization);
  const {
    sendRequest: checkValid,
    data: validateData,
    loading: validateDataLoading,
  } = useApi<IValidateConfiguration>(getRequest);

  const [status, setStatus] = useState(EConfigurationStates.default);
  const [defaultErrorData, setDefaultErrorData] = useState<IApiError | null>(null);

  const navigate = useNavigate();

  const isAdmin = useAppSelector(checkIsAdmin);

  useEffect(() => {
    if (isAdmin !== null && !isAdmin && activeTabKey === tabId) checkValid(validateSynchronizationUrl(systemId));
  }, [systemId, isAdmin]);

  useEffect(() => {
    setStatus(
      defaultErrorData
        ? EConfigurationStates.defaultError
        : data && !loading
        ? data.isSuccess
          ? EConfigurationStates.success
          : EConfigurationStates.objectError
        : EConfigurationStates.default
    );
  }, [data, defaultErrorData]);

  useEffect(() => {
    if (activeTabKey === tabId && status !== EConfigurationStates.default && !loading) {
      setStatus(EConfigurationStates.default);
    }
  }, [activeTabKey]);

  const onStart = useCallback(async () => {
    setStatus(EConfigurationStates.default);
    setDefaultErrorData(null);
    const resError = await startConfiguration(startSynchronizationUrl(systemId), {});
    if (resError) {
      setDefaultErrorData((resError as any)?.response?.data as IApiError);
    }
  }, [startConfiguration, systemId]);

  const onFindErrors = useCallback(() => {
    if (data?.additionalInformation) {
      if (data.additionalInformation.callCodesErrors) {
        onChangeTab(ESystemTabsIds.callCodes, [{ name: EPageQueryParams.needCheckErrors, val: true }]);
      } else if (data.additionalInformation.deviceSettingsErrors) {
        onChangeTab(ESystemTabsIds.BVConfiguration, [{ name: EPageQueryParams.needCheckErrors, val: true }]);
      } else if (data.additionalInformation.isNotSecurityKeys) {
        onChangeTab(ESystemTabsIds.basic);
      }
    }
  }, [data, onChangeTab]);

  const onFinish = useCallback(() => {
    navigate(paths.systems);
  }, [navigate]);

  const onlyAllFlatsHaveCallBlockId = useMemo(
    () =>
      data?.additionalInformation &&
      !data.additionalInformation.allFlatsHaveCallBlockId &&
      data.additionalInformation.callCodesErrors === 0 &&
      data.additionalInformation.deviceSettingsErrors === 0 &&
      !data?.additionalInformation?.isNotSecurityKeys,
    [data]
  );

  return (
    <div className="configuration-tab">
      {status === EConfigurationStates.default ? (
        <>
          {(validateData?.hasArchiveDeviceVersion ||
            !isAccessGroupsCheck ||
            validateData?.hasArchiveDeviceVersion ||
            !isAccessGroupsCheck) && (
            <div className="configuration-tab__icon">
              <DangerIcon />
            </div>
          )}

          <div className="configuration-tab__title">Синхронизация с сервером конфигурации</div>
          {(validateData?.hasArchiveDeviceVersion || !isAccessGroupsCheck) && (
            <div className="configuration-tab__sub-title">Обратите внимание на следующие предупреждения:</div>
          )}
          {!isAccessGroupsCheck && (
            <div className="configuration-tab__text configuration-tab__text_notify">
              Синхронизация будет осуществлена без квартирных ключей объекта
            </div>
          )}
          {validateData?.hasArchiveDeviceVersion && (
            <div className="configuration-tab__text configuration-tab__text_notify">
              Для оборудования выбрана архивная версия программного обеспечения, <br /> поддержка которой прекращена
            </div>
          )}
          <Button disabled={loading || !permissions.edit} onClick={onStart}>
            Начать
          </Button>
          {(loading || validateDataLoading) && (
            <div className="configuration-tab__loader">
              <Loader color={ELoaderColor.blue} />
            </div>
          )}
        </>
      ) : status === EConfigurationStates.success ? (
        <>
          <div className="configuration-tab__icon">
            <SuccessIcon />
          </div>
          <div className="configuration-tab__title">
            Синхронизация с сервером конфигурации <br /> проведена успешно
          </div>
        </>
      ) : status === EConfigurationStates.objectError ? (
        <>
          <div className="configuration-tab__icon">
            <ErrorIcon />
          </div>
          <div className="configuration-tab__title">
            Синхронизация не выполнена. <br />
          </div>
          <div className="configuration-tab__error-message">
            <div className="configuration-tab__text">
              {data?.additionalInformation?.isNotSecurityKeys && 'Ключи безопасности не сгенерированы'}
              {onlyAllFlatsHaveCallBlockId ? (
                <>
                  Чтобы совершить синхронизацию с сервером конфигурации, <br /> необходимо на вкладке "Распределение
                  квартир" задать БВ для всех квартир.
                </>
              ) : (
                <>
                  Чтобы совершить синхронизацию с сервером конфигурации <br /> необходимо исправить ошибки в данных.
                </>
              )}
            </div>
            <div className="configuration-tab__text">
              {data?.additionalInformation?.callCodesErrors !== 0 && (
                <>
                  {`Коды вызова: ${data?.additionalInformation?.callCodesErrors}`}
                  <br />
                </>
              )}
              {data?.additionalInformation?.deviceSettingsErrors !== 0 &&
                `Настройки оборудования: ${data?.additionalInformation?.deviceSettingsErrors}`}
              {!onlyAllFlatsHaveCallBlockId && !data?.additionalInformation?.allFlatsHaveCallBlockId && (
                <>
                  <br />
                  Не для всех квартир заданы БВ
                </>
              )}
            </div>
          </div>
          {!onlyAllFlatsHaveCallBlockId && (
            <div className="configuration-tab__buttons">
              <Button disabled={!permissions.edit} onClick={onFindErrors}>
                Проверить ошибки
              </Button>
              <Button disabled={!permissions.edit} onClick={onStart} type={ButtonType.outline}>
                Запустить повторно
              </Button>
            </div>
          )}
        </>
      ) : status === EConfigurationStates.defaultError && defaultErrorData ? (
        <>
          <div className="configuration-tab__icon">
            <ErrorIcon />
          </div>
          <div className="configuration-tab__title">{defaultErrorData.message || ' Синхронизация не выполнена. '}</div>
          <div className="configuration-tab__error-message">
            <div className="configuration-tab__text">
              Посмотрите список ошибок ниже
              <br /> и запустите повторную синхронизацию после их устранения.
            </div>
          </div>
          <div className="configuration-tab__buttons">
            <Button disabled={!permissions.edit} onClick={onStart}>
              Запустить повторно
            </Button>
          </div>
          <div className="configuration-tab__errors">{defaultErrorData?.stackTrace}</div>
        </>
      ) : null}
      {permissions.edit && (
        <div className="tab-nav-buttons">
          <Button disabled={loading || status !== EConfigurationStates.success} onClick={onFinish}>
            Готово
          </Button>
        </div>
      )}
    </div>
  );
};

export default ConfigurationTab;
